import { useState } from "react";
import Box from "@mui/material/Box";
import PaymentsIcon from "@mui/icons-material/Payments";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BarChartIcon from "@mui/icons-material/BarChart";

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";

import {
  PersonAdd,
  People,
  Feedback,
  BugReport,
  CreditCard,
  Fingerprint,
  HourglassTop,
  AddCard,
  Rule,
  DashboardCustomizeRounded,
  VideogameAsset,
  Security,
  DashboardOutlined,
  List,
  CheckBox,
  AttachMoney,
  Block,
  AppBlocking,
  AdminPanelSettings,
} from "@mui/icons-material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import SubMenu from "./SubMenu";
import GridViewIcon from "@mui/icons-material/GridView";
import StyleIcon from "@mui/icons-material/Style";

type MenuName =
  | "payoutMenu"
  | "withdrawalsMenu"
  | "kycMenu"
  | "gameMenu"
  | "gameRecords"
  | "couponMenu"
  | "userMenu";
const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    payoutMenu: true,
    gameMenu: true,
    kycMenu: true,
    userMenu: true,
    gameRecords: true,
    withdrawalsMenu: true,
    couponMenu: true,
  });
  const { permissions } = usePermissions();
  const [open] = useSidebarState();
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const toggleGameMenu = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const toggleGameRecords = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  return (
    <Box
      sx={{
        width: open ? 300 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {permissions.includes("finance") && (
        <>
          <MenuItemLink
            to="/payment-dashboard"
            state={{ _scrollToTop: true }}
            primaryText={`Payment Dashboard`}
            leftIcon={<DashboardCustomizeRounded />}
            dense={dense}
          />

          <MenuItemLink
            to="/transactions"
            state={{ _scrollToTop: true }}
            primaryText={`Wallet Transactions`}
            leftIcon={<CreditCard />}
            dense={dense}
          />
        </>
      )}
      {permissions.includes("support") && (
        <>
          <SubMenu
            handleToggle={() => handleToggle("userMenu")}
            isOpen={state.userMenu}
            name="Users"
            icon={<People />}
            dense={dense}
          >
            <MenuItemLink
              to="/users"
              state={{ _scrollToTop: true }}
              primaryText={`List`}
              leftIcon={<List />}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games"
            icon={<VideogameAsset />}
            dense={dense}
          >
            <MenuItemLink
              to="/game-histories"
              state={{ _scrollToTop: true }}
              primaryText={`History`}
              leftIcon={<HistoryToggleOffIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/game-table"
              state={{ _scrollToTop: true }}
              primaryText={`Live Games`}
              leftIcon={<LiveTvIcon />}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("payoutMenu")}
            isOpen={state.payoutMenu}
            name="Deposits"
            icon={<AttachMoney />}
            dense={dense}
          >
            <MenuItemLink
              to="/deposits"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={<AttachMoney />}
              dense={dense}
            />
            <MenuItemLink
              to="/incomplete-deposit"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Deposit"
              leftIcon={<AddCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Wallet Transactions`}
              leftIcon={<CreditCard />}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("withdrawalsMenu")}
            isOpen={state.withdrawalsMenu}
            name="Withdrawals"
            icon={<PaymentsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/withdrawals"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />
            <MenuItemLink
              to="/incomplete-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Withdrawal "
              leftIcon={<Rule />}
              dense={dense}
              title="Sync Withdrawal"
            />
            <MenuItemLink
              to="/incomplete-juspay-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Juspay Withdrawal"
              leftIcon={<Rule />}
              dense={dense}
              title="Incomplete Juspay Withdrawal"
            />
            <MenuItemLink
              to="/processing-requests"
              state={{ _scrollToTop: true }}
              primaryText="In Process"
              leftIcon={<HourglassTop />}
              dense={dense}
            />
            <MenuItemLink
              to="/processed-requests"
              state={{ _scrollToTop: true }}
              primaryText="Processed Requests"
              leftIcon={<CheckBox />}
              dense={dense}
              title="Processed Requests"
            />
          </SubMenu>
        </>
      )}
      {permissions.includes("admin") && (
        <>
          <DashboardMenuItem primaryText="Dashboard" />

          <MenuItemLink
            to="/daily-report"
            state={{ _scrollToTop: true }}
            primaryText={`Daily Report`}
            leftIcon={<DashboardOutlined />}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("userMenu")}
            isOpen={state.userMenu}
            name="Users"
            icon={<People />}
            dense={dense}
          >
            <MenuItemLink
              to="/users-graph"
              state={{ _scrollToTop: true }}
              primaryText={`Bar Graph`}
              leftIcon={<BarChartIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/users"
              state={{ _scrollToTop: true }}
              primaryText={`List`}
              leftIcon={<List />}
              dense={dense}
            />
            <MenuItemLink
              to="/users/blocked"
              state={{ _scrollToTop: true }}
              primaryText={`Blocked List`}
              leftIcon={<Block />}
              dense={dense}
            />
            <MenuItemLink
              to="/blocked-device"
              state={{ _scrollToTop: true }}
              primaryText={`Blocked Devices`}
              leftIcon={<AppBlocking />}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle("gameMenu")}
            isOpen={state.gameMenu}
            name="Games"
            icon={<VideogameAsset />}
            dense={dense}
          >
            <MenuItemLink
              to="/gameplay-dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Dashboard`}
              leftIcon={<GridViewIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/game-histories"
              state={{ _scrollToTop: true }}
              primaryText={`History`}
              leftIcon={<HistoryToggleOffIcon />}
              dense={dense}
            />
            <MenuItemLink
              to="/game-table"
              state={{ _scrollToTop: true }}
              primaryText={`Live Games`}
              leftIcon={<LiveTvIcon />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/bug-reports"
            state={{ _scrollToTop: true }}
            primaryText={`Bug Reports`}
            leftIcon={<BugReport />}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("couponMenu")}
            isOpen={state.couponMenu}
            name="Coupons"
            icon={<LocalOfferIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/coupons"
              state={{ _scrollToTop: true }}
              primaryText={`Create`}
              leftIcon={<StyleIcon />}
              dense={dense}
            />

            <MenuItemLink
              to="/coupon-history"
              state={{ _scrollToTop: true }}
              primaryText={`History`}
              leftIcon={<HistoryToggleOffIcon />}
              dense={dense}
            />
          </SubMenu>

          <MenuItemLink
            to="/feedbacks"
            state={{ _scrollToTop: true }}
            primaryText={`Feedbacks`}
            leftIcon={<Feedback />}
            dense={dense}
          />
          <SubMenu
            handleToggle={() => handleToggle("kycMenu")}
            isOpen={state.kycMenu}
            name="Kyc"
            icon={<Security />}
            dense={dense}
          >
            <MenuItemLink
              to="/kyc-dashboard"
              state={{ _scrollToTop: true }}
              primaryText={`Dashboard`}
              leftIcon={<DashboardOutlined />}
              dense={dense}
            />
            <MenuItemLink
              to="/kyc-records"
              state={{ _scrollToTop: true }}
              primaryText={`Records`}
              leftIcon={<Fingerprint />}
              dense={dense}
            />
          </SubMenu>
          <MenuItemLink
            to="/referrals"
            state={{ _scrollToTop: true }}
            primaryText={`Referrals`}
            leftIcon={<PersonAdd />}
            dense={dense}
          />

          <MenuItemLink
            to="/payment-dashboard"
            state={{ _scrollToTop: true }}
            primaryText="Payment Dashboard"
            leftIcon={<DashboardCustomizeRounded />}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle("payoutMenu")}
            isOpen={state.payoutMenu}
            name="Deposits"
            icon={<AttachMoney />}
            dense={dense}
          >
            <MenuItemLink
              to="/deposits"
              state={{ _scrollToTop: true }}
              primaryText="Deposits"
              leftIcon={<AttachMoney />}
              dense={dense}
            />
            <MenuItemLink
              to="/top-deposits"
              state={{ _scrollToTop: true }}
              primaryText="Top Deposits"
              leftIcon={<CurrencyExchangeIcon />}
              dense={dense}
              title="top-deposits"
            />
            <MenuItemLink
              to="/incomplete-deposit"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Deposit"
              leftIcon={<AddCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Wallet Transactions`}
              leftIcon={<CreditCard />}
              dense={dense}
            />
            <MenuItemLink
              to="/admin-wallet-transactions"
              state={{ _scrollToTop: true }}
              primaryText={`Manual Transactions`}
              leftIcon={<AdminPanelSettings />}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle("withdrawalsMenu")}
            isOpen={state.withdrawalsMenu}
            name="Withdrawals"
            icon={<PaymentsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/withdrawals"
              state={{ _scrollToTop: true }}
              primaryText="Withdrawals"
              leftIcon={<PaymentsIcon />}
              dense={dense}
              title="withdrawals"
            />
            <MenuItemLink
              to="/top-withdrawals"
              state={{ _scrollToTop: true }}
              primaryText="Top Withdrawals"
              leftIcon={<PriceChangeIcon />}
              dense={dense}
              title="top-withdrawals"
            />
            <MenuItemLink
              to="/incomplete-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Withdrawal "
              leftIcon={<Rule />}
              dense={dense}
              title="Sync Withdrawal"
            />
            <MenuItemLink
              to="/incomplete-juspay-withdrawal"
              state={{ _scrollToTop: true }}
              primaryText="Incomplete Juspay Withdrawal"
              leftIcon={<Rule />}
              dense={dense}
              title="Incomplete Juspay Withdrawal"
            />
            <MenuItemLink
              to="/processing-requests"
              state={{ _scrollToTop: true }}
              primaryText="In Process"
              leftIcon={<HourglassTop />}
              dense={dense}
            />
            <MenuItemLink
              to="/processed-requests"
              state={{ _scrollToTop: true }}
              primaryText="Processed Requests"
              leftIcon={<CheckBox />}
              dense={dense}
              title="Processed Requests"
            />
          </SubMenu>
        </>
      )}
    </Box>
  );
};
export default Menu;
