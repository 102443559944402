import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  ShowButton,
  FunctionField,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  SelectArrayInput,
  FilterForm,
  FilterButton,
  Link,
  Button,
} from "react-admin";

import { toast } from "react-toastify";
import config from "../../config";
import { SetStateAction, useState } from "react";
import axios from "axios";
import { getItemWithExpiry } from "../../utils/sessionData";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { DeleteForever, ModeEdit } from "@mui/icons-material";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Wallet"
    source="wallet"
    choices={[
      { id: "mainWallet", name: "Main Wallet" },
      { id: "winningWallet", name: "Winning Wallet" },
      { id: "bonusWallet", name: "Bonus Wallet" },
    ]}
  />,

  <SelectArrayInput
    label="Filter by Cashback"
    source="bonusType"
    choices={[
      { id: "percentage", name: "Percentage" },
      { id: "rupees", name: "Rupees" },
    ]}
  />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const CouponHistory = (props: object) => {
  const refresh = useRefresh();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [promoCodeToDelete, setPromoCodeToDelete] = useState(null);
  const { permissions } = usePermissions();

  const handleDelete = async (promoCode: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await axios.delete(
        `${config.REST_ENDPOINT}/coupon/${promoCode}?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Coupon deleted successfully!");
        refresh();
      } else {
        toast.error("Failed to delete the coupon");
      }
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  const openDeleteConfirmation = (promoCode: SetStateAction<null>) => {
    setPromoCodeToDelete(promoCode);
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search Promo Code"
        source="promoCode"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Min Amount"
        source="minAmount"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Max Amount"
        source="maxAmount"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      handleDelete(promoCodeToDelete || "");
    }
    closeDeleteConfirmation();
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Coupon History</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        {permissions.includes("admin") && (
          <DatagridConfigurable bulkActionButtons={false}>
            <TextField source="promoCode" label="Promo Code" />
            <TextField source="description" label="Description" />
            <TextField source="minAmount" label="Min Amount" />
            <TextField source="maxAmount" label="Max Amount" />
            <FunctionField
              label="Cashback "
              render={(record: { bonusAmount: any; bonusType: any }) => {
                const { bonusAmount, bonusType } = record;
                if (bonusType === "percentage") {
                  return `${bonusAmount}%`;
                } else {
                  return bonusAmount;
                }
              }}
            />
            <TextField source="wallet" label="Cashback Credit Wallet" />
            <DateField source="expireAt" showTime label="Coupon Expire Date" />

            <FunctionField
              label="Visibility"
              render={(record: any) => {
                const visibility = record.visible;
                const expireAt = new Date(record.expireAt);
                const currentDate = new Date();
                const isExpired = expireAt < currentDate;
                const textColor = isExpired
                  ? "red"
                  : visibility
                  ? "green"
                  : "black";

                return (
                  <span style={{ color: textColor }}>
                    {visibility ? "Visible" : "Invisible"}
                    {isExpired ? " (Expired)" : ""}
                  </span>
                );
              }}
            />
            <FunctionField
              textAlign="center"
              label="Actions"
              render={(record: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginRight: "20px",
                      flexDirection: "row",
                    }}
                  >
                    <Link to={`/coupon-history/${record?.promoCode}/edit`}>
                      <ModeEdit
                        titleAccess="Edit Coupon"
                        fontSize="large"
                        sx={{
                          "&:hover": {
                            color: "blue",
                          },
                          marginRight: "15px",
                        }}
                        color="secondary"
                      />
                    </Link>
                    <DeleteForever
                      onClick={() => {
                        openDeleteConfirmation(record.promoCode);
                      }}
                      color="warning"
                      titleAccess="Delete Coupon"
                      fontSize="large"
                      sx={{
                        "&:hover": { color: "red" },
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                    />
                    <ShowButton
                      resource={`/coupon-history/${record.promoCode}`}
                    />
                  </div>
                );
              }}
            />
          </DatagridConfigurable>
        )}
      </List>
      <Dialog open={deleteConfirmation} onClose={closeDeleteConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteConfirmation(false)}
            color="primary"
            label="Cancel"
          ></Button>
          <Button
            onClick={() => handleDeleteConfirmation(true)}
            color="primary"
            label="Yes"
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CouponHistory;
