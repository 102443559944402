import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { isEmpty, omit } from "lodash";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { callRestApi } from "../../utils/callRestApi";
import { useParams } from "react-router";
import { AxiosResponse } from "axios";
import { CouponType } from "./CouponType";

const EditCoupon = () => {
  const { id } = useParams();
  const [couponData, setCouponData] = useState<CouponType>({
    description: "Coupon Description",
    minAmount: "0",
    maxAmount: "0",
    expireAt: "",
    bonusAmount: "0",
    bonusType: "percentage",
    wallet: "bonusWallet",
  });

  const [loading, setLoading] = useState(false);
  const defaultFormValues = {
    description: "Coupon Description",
    minAmount: "0",
    maxAmount: "0",
    expireAt: "",
    bonusAmount: "0",
    bonusType: "percentage",
    wallet: "bonusWallet",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<CouponType>({});

  const bonusTypeValue = watch("bonusType", couponData.bonusType);
  const walletValue = watch("wallet", couponData.wallet);

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchCouponDetails(id || "");
    }
  }, [id]);

  const fetchCouponDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data } = (await callRestApi(
        `/users/coupon/${id}`,
        "GET",
        {}
      )) as AxiosResponse;
      if (!isEmpty(data)) {
        reset(data);
        setCouponData(data);
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const requestData = omit(data, ["promoCode", "users"]);
      const { status } = (await callRestApi(
        `/coupon-history/${id}`,
        "PATCH",
        requestData
      )) as AxiosResponse;
      if (status === 200) {
        toast("Coupon Updated", {
          type: "success",
        });
      }
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card style={{ padding: "25px" }}>
      <h4>Edit Coupon - {id} </h4>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="description">Description</InputLabel>
              <TextField
                {...register("description")}
                id="description"
                name="description"
                placeholder="Description"
                fullWidth
                error={!!errors.description}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="minAmount">Minimum Amount</InputLabel>
              <TextField
                {...register("minAmount")}
                id="minAmount"
                name="minAmount"
                placeholder="Minimum Amount"
                fullWidth
                error={!!errors.minAmount}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="maxAmount">Maximum Amount</InputLabel>
              <TextField
                {...register("maxAmount")}
                id="maxAmount"
                name="maxAmount"
                placeholder="Maximum Amount"
                fullWidth
                error={!!errors.maxAmount}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="bonusAmount">Bonus Amount</InputLabel>
              <TextField
                {...register("bonusAmount")}
                id="bonusAmount"
                name="bonusAmount"
                placeholder="Bonus Amount"
                fullWidth
                error={!!errors.bonusAmount}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel>Bonus Type</InputLabel>
              <RadioGroup
                aria-label="bonusType"
                name="bonusType"
                defaultValue={bonusTypeValue}
              >
                <FormControlLabel
                  value="percentage"
                  control={<Radio />}
                  label="Percentage"
                  {...register("bonusType")}
                />
                <FormControlLabel
                  value="rupees"
                  control={<Radio />}
                  label="Rupees"
                  {...register("bonusType")}
                  checked={bonusTypeValue === "rupees"}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel>Wallet</InputLabel>
              <RadioGroup
                aria-label="wallet"
                name="wallet"
                defaultValue={walletValue}
              >
                <FormControlLabel
                  value="mainWallet"
                  control={<Radio />}
                  label="Main Wallet"
                  {...register("wallet")}
                />
                <FormControlLabel
                  value="winningWallet"
                  control={<Radio />}
                  label="Winning Wallet"
                  {...register("wallet")}
                />
                <FormControlLabel
                  value="bonusWallet"
                  control={<Radio />}
                  label="Bonus Wallet"
                  {...register("wallet")}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="expireAt">Expiration Date</InputLabel>
              <TextField
                {...register("expireAt")}
                id="expireAt"
                name="expireAt"
                type="datetime-local"
                fullWidth
                defaultValue={dayjs(couponData.expireAt).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                error={!!errors.expireAt}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box display="flex" justifyContent="space-between">
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={() => {
                  reset(defaultFormValues);
                  setCouponData(defaultFormValues);
                }}
                style={{ maxWidth: "50%" }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                style={{ maxWidth: "50%" }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};
export default EditCoupon;
