import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  FunctionField,
  usePermissions,
  DateTimeInput,
} from "react-admin";

import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const payoutStatusChoices = [
  { id: "SUCCESS", name: "Success" },
  { id: "FAILED", name: "Failed" },
  { id: "PENDING", name: "Pending" },
];
const transactionFilters = [
  <SelectArrayInput
    label="Filter by Status"
    source="status"
    choices={[
      { id: "success", name: "Success" },
      { id: "failed", name: "Failed" },
      { id: "pending", name: "Pending" },
      { id: "refund", name: "Refund" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by Payout Status (Records from Sept 04 2023)"
    source="payoutStatus"
    choices={payoutStatusChoices}
  />,
  <SelectArrayInput
    label="Filter by CashFree Payout Status (Records before Sept 04 2023)"
    source="cashfreePayoutStatus"
    choices={payoutStatusChoices}
  />,
  <SelectArrayInput
    label="Filter by Payment Gateway"
    source="paymentGateway"
    choices={[
      { id: "juspay", name: "JusPay" },
      { id: "cashfree", name: "CashFree" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by txnMode"
    source="txnMode"
    choices={[
      { id: "deposit", name: "Deposit" },
      { id: "withdrawalRequest", name: "Withdrawal" },
      { id: "withdrawalRefund", name: "Withdrawal Refund" },
      { id: "admin", name: "Admin" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by txnType"
    source="txnType"
    choices={[
      { id: "credit", name: "Credit" },
      { id: "debit", name: "Withdrawal" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by paymentMode"
    source="paymentMode"
    choices={[
      { id: "UPI", name: "UPI" },
      { id: "CARD", name: "Card" },
    ]}
  />,
  <SelectArrayInput
    label="Filter by payout Type"
    source="payoutType"
    choices={[
      { id: "IMPS", name: "Bank Transfer" },
      { id: "UPI", name: "UPI" },
    ]}
  />,

  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const WalletTransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
      <SearchInput
        placeholder="Search OrderID"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search TransferId"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Wallet Transactions </h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <FunctionField
            label="TransferId"
            render={(record: any) => {
              if (
                ["withdrawalRequest", "withdrawalRefund"].includes(
                  record.txnMode
                )
              )
                return <TextField source="id" label="TransferId" />;
            }}
          />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="amount" />
          <TextField source="mainwallet" />
          <TextField source="winwallet" />
          <TextField source="txnMode" />
          <TextField source="payoutType" />
          <TextField source="paymentMode" />
          <TextField source="paymentGateway" />
          <TextField source="txnType" />

          <FunctionField
            label="Payout Status"
            render={(record: any) => {
              if (record.payoutStatus || record.cashfreePayoutStatus) {
                if (record.payoutStatus) {
                  if (record.payoutStatus === "PENDING") {
                    return <ChipField source="payoutStatus" color="warning" />;
                  } else if (record.payoutStatus === "SUCCESS") {
                    return <ChipField source="payoutStatus" color="success" />;
                  } else {
                    return <ChipField source="payoutStatus" color="error" />;
                  }
                } else {
                  if (record.cashfreePayoutStatus === "PENDING") {
                    return (
                      <ChipField
                        source="cashfreePayoutStatus"
                        color="warning"
                      />
                    );
                  } else if (record.cashfreePayoutStatus === "SUCCESS") {
                    return (
                      <ChipField
                        source="cashfreePayoutStatus"
                        color="success"
                      />
                    );
                  } else {
                    return (
                      <ChipField source="cashfreePayoutStatus" color="error" />
                    );
                  }
                }
              }
            }}
          />

          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.status === "pending") {
                return <ChipField source="status" color="warning" />;
              } else if (record.status === "success") {
                return <ChipField source="status" color="success" />;
              } else {
                return <ChipField source="status" color="error" />;
              }
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default WalletTransactions;
