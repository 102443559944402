import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  FunctionField,
  usePermissions,
  DateTimeInput,
} from "react-admin";

import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const transactionFilters = [
  <SelectArrayInput
    label="Filter by Wallet"
    source="wallet"
    choices={[
      { id: "mainWallet", name: "Main Wallet" },
      { id: "winningWallet", name: "Winning Wallet" },
      { id: "bonusWallet", name: "Bonus Wallet" },
    ]}
  />,

  <DateTimeInput source="startDate" alwaysOn />,
  <DateTimeInput source="endDate" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row">
    <FilterForm filters={transactionFilters} />
  </Stack>
);

const ManualTransactions = (props: any) => {
  const { permissions } = usePermissions();
  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        value
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Amount"
        source="amount"
        resettable
        alwaysOn
      />
    </Filter>
  );
  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Manual Transactions </h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <UserNameHyperlink
            label="Transaction By"
            source="transactionByUserName"
            to="transactionBy"
          />
          <FunctionField
            label="Amount"
            render={(record: any) => {
              const amount = record.amount;
              const type = record.type;
              const textColor =
                type === "deposit" || type === "bonus" ? "green" : "red";
              const sign = type === "deposit" || type === "bonus" ? "+" : "-";
              return (
                <span style={{ color: textColor }}>
                  {sign}
                  {amount}
                </span>
              );
            }}
          />
          <FunctionField
            label="Wallet"
            render={(record: any) => {
              const wallet = record.wallet;
              let walletText = "";

              if (wallet === "mainWallet") {
                walletText = "Main Wallet";
              } else if (wallet === "winningWallet") {
                walletText = "Winning Wallet";
              } else if (wallet === "bonusWallet") {
                walletText = "Bonus Wallet";
              } else {
                walletText = wallet;
              }
              return walletText;
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};
export default ManualTransactions;
