import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "./sessionData";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

export const callRestApi = async (
  endpoint: string,
  method: string = "GET",
  body: object = {},
  query: string = ""
) => {
  const user = getItemWithExpiry("user");
  if (!user.token) {
    toast.error("You have no access");
    return;
  }
  let url: string = `${config.REST_ENDPOINT}${endpoint}?key=${config.MAINTENANCE_BYPASS_KEY}`;
  if (!isEmpty(query)) {
    url = `${url}&${query}`;
  }

  return await axios({
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
    method,
    url,
    data: body,
  });
};