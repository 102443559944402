import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  WrapperField,
  ShowButton,
  FunctionField,
  Button,
  useRefresh,
  usePermissions,
  DatagridConfigurable,
  FilterForm,
  DateTimeInput,
  FilterButton,
} from "react-admin";

import { callRestApi } from "../../utils/callRestApi";
import { toast } from "react-toastify";
import config from "../../config";
import { useState } from "react";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";
import UserNameHyperlink from "../../components/UserNameHyperlink";

const Users = (props: object) => {
  const refresh = useRefresh();
  const [userData, setUserData] = useState<{
    _id: string;
    isBlocked: boolean;
  } | null>(null);
  const { permissions } = usePermissions();

  const handleBlock = async (id: string, isBlocked: boolean) => {
    try {
      if (!permissions.includes("admin")) {
        toast.error("You don't have access to this functionality");
        return;
      }
      const endpoint = isBlocked ? "/unblock" : "/block";
      const url = `${endpoint}`;
      await callRestApi(url, "PATCH", { id });
      refresh();
      toast.success(
        !isBlocked
          ? "Blocked User Successfully!"
          : "Unblocked User Successfully!"
      );
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="username"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Name"
        source="name"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Mobile Number"
        source="mobileNo.number"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "MobileNo must be at least 10 Numbers long";
          }
          return undefined;
        }}
      />
      <SearchInput
        placeholder="Search App Version"
        source="appVersion"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Users List</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <WrapperField label="Mobile Number">
            <TextField source="mobileNo.countryCode" />
            <>{"-"}</>
            <TextField source="mobileNo.number" />
          </WrapperField>
          <UserNameHyperlink label="User Name" source="username" to="userId" />
          <TextField source="name" label="Name" />
          <TextField source="totalGamePlay" label="Game Played" />
          <TextField source="wallets.mainWallet.amount" label="Main Wallet" />
          <TextField source="wallets.winningWallet.amount" label="Win Wallet" />
          <TextField source="wallets.bonusWallet.amount" label="Bonus Wallet" />
          <TextField source="appVersion" label="App Version" />
          <DateField source="createdAt" showTime label="Registered At" />
          {permissions.includes("admin") && (
            <FunctionField
              label="Action"
              render={(record: any) => {
                return (
                  <>
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      label={!record.isBlocked ? "Block" : "Unblock"}
                      onClick={() => handleBlock(record.id, record.isBlocked)}
                    />
                  </>
                );
              }}
            />
          )}
          <ShowButton />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Users;
