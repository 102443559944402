import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  useNotify,
  useRefresh,
  DateTimeInput,
} from "react-admin";
import UserNameHyperlink from "../components/UserNameHyperlink";
import { useState } from "react";
import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import KycZoopImageModal from "../components/KycZoopImageModal";

const KycZoop = (props: object) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalData, setImageModalData] = useState({});
  const { permissions } = usePermissions();

  const notify = useNotify();
  const refresh = useRefresh();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by CardType"
      source="kyc.cardType"
      choices={[
        { id: "ADHAAR", name: "Adhaar" },
        { id: "PAN", name: "PAN" },
        { id: "VOTERID", name: "Voter ID" },
        { id: "DRIVING_LICENSE", name: "Driving License" },
      ]}
    />,

    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>KYC Records</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <FunctionField
            label="Card Type"
            render={(record: any) => {
              return record.kyc.cardNumber ? "AADHAR NO" : record.kyc.cardType;
            }}
          />
          <TextField source="kycStatus" label="Kyc Status" />
          <FunctionField
            label="Cards"
            render={(record: any) => {
              if (record.kyc.cardNumber) {
                return (
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="small outlined button group"
                  >
                    <Button
                      color="success"
                      onClick={() => {
                        setImageModalOpen(true);
                        setImageModalData(() => {
                          return { ...record };
                        });
                      }}
                    >
                      Card Number
                    </Button>
                  </ButtonGroup>
                );
              } else {
                return (
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="small outlined button group"
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        setImageModalOpen(true);
                        setImageModalData(() => {
                          return {
                            ...record,
                            imageToDisplay: record.kyc.frontImageUrl,
                          };
                        });
                      }}
                    >
                      Front Image
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => {
                        setImageModalOpen(true);
                        setImageModalData(() => {
                          return { ...record, imageToDisplay: "aadharBackUrl" };
                        });
                      }}
                    >
                      Back Image
                    </Button>
                  </ButtonGroup>
                );
              }
            }}
          />
          <DateField source="createdAt" showTime label="Created At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
      {imageModalOpen && (
        <KycZoopImageModal
          open={imageModalOpen}
          close={() => setImageModalOpen(false)}
          data={imageModalData}
        />
      )}
    </>
  );
};

export default KycZoop;
