import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  NumberField,
  FunctionField,
  FilterForm,
  DateTimeInput,
} from "react-admin";
import UserNameHyperlink from "../../components/UserNameHyperlink";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";

const GameHistory = (props: object) => {
  const { permissions } = usePermissions();

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Join Amount"
        source="joinAmount"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Table ID"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Game Histories</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink source="userName" to="userId" label="User Name" />
          <FunctionField
            label="Table Id"
            render={(record: any) => (
              <Link to={record?.tableId}>{record?.tableId}</Link>
            )}
          />
          <NumberField source="joinAmount" label="Join Amount" />
          <NumberField source="winOrLoseAmount" label="Win/Loss Amount" />
          <TextField source="currency" label="Currency" />
          <TextField source="outcome" label="Outcome" />
          <FunctionField
            source="startedAt"
            label="Created At"
            render={(record: any) => {
              const dateSource = record.startedAt ? "startedAt" : "createdAt";
              const dateValue = record[dateSource]
                ? new Date(record[dateSource])
                : null;
              return (
                <>
                  <span>{dateValue ? dateValue.toLocaleDateString() : ""}</span>
                  {", "}
                  <span>{dateValue ? dateValue.toLocaleTimeString() : ""}</span>
                </>
              );
            }}
          />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default GameHistory;
