const StatusIndicator = ({ backgroundColor, total, label }: any) => (
  <div
    style={{ display: "inline-block", marginRight: "10px", marginTop: "8px" }}
  >
    <div
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "80%",
        backgroundColor: backgroundColor,
        display: "inline-block",
        marginRight: "5px",
      }}
    />
    <b>
      {label}: {total}
    </b>
  </div>
);

export default StatusIndicator;
