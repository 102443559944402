import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import StatsCard from "../../components/StatsCard";
import {
  DescriptionOutlined,
  Cancel,
  HourglassEmpty,
  CheckCircle,
} from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { Box } from "@mui/system";
import { callRestApi } from "../../utils/callRestApi";
import { Typography } from "@mui/material";
import axios from "axios";
import config from "../../config";
import { getItemWithExpiry } from "../../utils";
import { toast } from "react-toastify";

const KycDashboard = () => {
  const [loading, setLoading] = useState(false);

  const [stats, setStats] = useState({
    totalKycDocuments: 0,
    totalPendingKyc: 0,
    totalRejectedKyc: 0,
    totalCompletedKyc: 0,
    totalInitiatedKyc: 0,
  });

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const filter = {
        startDate,
        endDate,
      };
      const response = await axios.get(
        `${config.REST_ENDPOINT}/kyc-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response && response.data) {
        const { data } = response;
        setStats(data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>KYC Dashboard</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total KYC Records</Typography>}
            value={stats.totalKycDocuments}
            icon={
              <DescriptionOutlined
                style={{ fontSize: "20px", color: "#808080" }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Initiated KYC</Typography>
            }
            value={stats.totalInitiatedKyc}
            icon={
              <AutorenewIcon style={{ fontSize: "20px", color: "#75C2F6" }} />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Completed KYC</Typography>
            }
            value={stats.totalCompletedKyc}
            icon={
              <CheckCircle style={{ fontSize: "20px", color: "#4CAF50" }} />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={
              <Typography fontWeight="bold">Total Rejected KYC</Typography>
            }
            value={stats.totalRejectedKyc}
            icon={<Cancel style={{ fontSize: "20px", color: "#F44336" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsCard
            title={<Typography fontWeight="bold">Total Pending KYC</Typography>}
            value={stats.totalPendingKyc}
            icon={
              <HourglassEmpty style={{ fontSize: "20px", color: "#FFC107" }} />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
export default KycDashboard;
