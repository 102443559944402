import { Admin, Resource, fetchUtils, CustomRoutes } from "react-admin";
import { Route } from "react-router";

import dataprovider from "./providers/dataProvider";
import authProvider from "./providers/AuthProvider";
import { QueryClient } from "react-query";

import { Layout } from "./layout";
import { getItemWithExpiry } from "./utils/sessionData";
import config from "./config";

import "./App.css";

// import Dashboard from "./modules/Dashboard";
import Users from "./modules/users/Users";
import BugReports from "./modules/BugReports";
import Feedbacks from "./modules/Feedbacks";
import GameConfig from "./modules/GameConfig";
import LoginPage from "./modules/LoginPage";
import ReferralRecords from "./modules/ReferralRecords";
import WalletTransaction from "./modules/WalletTransactions";
import Configuration from "./modules/Configuaration";
import KycZoop from "./modules/KycZoop";
import Details from "./modules/users/Details";
import Withdrawals from "./modules/Withdrawals";
import Deposits from "./modules/deposits";
import GameHistory from "./modules/game-history/GameHistory";
import ScoreBoard from "./modules/game-history/Scoreboard";
import IncompleteWithdrawal from "./modules/Incomplete-Withdrawal";
import IncompleteDeposits from "./modules/IncompleteDeposits";
import PaymentDashboard from "./modules/PaymentDashboard";
import GameplayDashboard from "./modules/GameplayDashboard";
import KycDashboard from "./modules/kyc/KycDashboard";
import GraphApp from "./modules/users/Graph";
import GameOngoing from "./modules/game-history/OngoingGames";
import IncompleteJuspayWithdrawal from "./modules/Incomplete-juspay-withdrawal";
import ProcessingRequests from "./modules/ProcessingRequests";
import ProcessedRequests from "./modules/ProcessedRequests";
import BlockedUsers from "./modules/users/BlockedUsers";
import BlockedDevices from "./modules/users/BlockedDevices";
import ManualTransactions from "./modules/ManualTransactions";
import Coupon from "./modules/coupons/CreateCoupon";
import DailyReport from "./modules/DailyReport";
import Dashboard from "./modules/Dashboard";
import CouponHistory from "./modules/coupons/CouponHistory";
import CouponDetails from "./modules/coupons/CouponDetails";
import EditCoupon from "./modules/coupons/EditCoupon";
import TopWithdrawals from "./modules/TopWithdrawals";
import TopDeposits from "./modules/TopDeposits";
import AdminPage from "./modules/Admin";

function App() {
  const attachHeaders = (url: string, options: fetchUtils.Options = {}) => {
    const customHeaders = (options.headers ||
      new Headers({
        Accept: "application/json",
      })) as Headers;
    // Get token from storage
    const { token } = getItemWithExpiry("user");
    // Attach token in header - which will be added on all api requests
    customHeaders.set("Authorization", `Bearer ${token}`);
    options.headers = customHeaders;
    return fetchUtils.fetchJson(url, options);
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        structuralSharing: false,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retryDelay: 10000,
      },
    },
  });

  return (
    <Admin
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataprovider(config.REST_ENDPOINT, attachHeaders)}
      requireAuth
      layout={Layout}
      queryClient={queryClient}
    >
      {(permissions) => (
        <>
          <CustomRoutes>
            <Route path="/configuration" element={<Configuration />} />
          </CustomRoutes>
          {(permissions.includes("admin") ||
            permissions.includes("finance")) && (
            <>
              <Resource name="payment-dashboard" list={PaymentDashboard} />
              <Resource name="admin" list={AdminPage} />
              <Resource name="transactions" list={WalletTransaction} />
            </>
          )}

          {(permissions.includes("admin") ||
            permissions.includes("support")) && (
            <>
              <Resource name="users" list={Users} show={Details} />
              <Resource name="admin" list={AdminPage} />
              <Resource
                name="game-histories"
                list={GameHistory}
                show={Details}
              />
              <CustomRoutes>
                <Route
                  path="game-histories/:tableId"
                  element={<ScoreBoard />}
                />
                <Route
                  path="game-histories/:tableId/:userId"
                  element={<ScoreBoard />}
                />
                <Route path="game-table/:tableId" element={<ScoreBoard />} />
              </CustomRoutes>
              <Resource name="kyc-records" list={KycZoop} />
              <Resource name="game-table" list={GameOngoing} />
              <Resource name="deposits" list={Deposits} />
              <Resource name="withdrawals" list={Withdrawals} />
              <Resource name="top-deposits" list={TopDeposits} />
              <Resource name="top-withdrawals" list={TopWithdrawals} />
              <Resource name="transactions" list={WalletTransaction} />
              <Resource name="processed-requests" list={ProcessedRequests} />
              <Resource name="processing-requests" list={ProcessingRequests} />
              <Resource name="incomplete-deposit" list={IncompleteDeposits} />
              <Resource
                name="incomplete-withdrawal"
                list={IncompleteWithdrawal}
              />
              <Resource
                name="incomplete-juspay-withdrawal"
                list={IncompleteJuspayWithdrawal}
              />
            </>
          )}

          {permissions.includes("admin") && (
            <>
              <Resource name="dashboard" list={Dashboard} />
              <Resource name="users-graph" list={GraphApp} />
              <Resource name="users/blocked" list={BlockedUsers} />
              <Resource
                name="admin-wallet-transactions"
                list={ManualTransactions}
              />
              <Resource name="blocked-device" list={BlockedDevices} />
              <Resource name="kyc-dashboard" list={KycDashboard} />
              <Resource name="gameplay-dashboard" list={GameplayDashboard} />
              <Resource name="bug-reports" list={BugReports} />
              <Resource name="feedbacks" list={Feedbacks} />
              <Resource name="referrals" list={ReferralRecords} />
              <Resource name="game-config" list={GameConfig} />
              <Resource name="coupons" list={Coupon} />
              <Resource
                name="coupon-history"
                list={CouponHistory}
                show={CouponDetails}
                edit={EditCoupon}
              />
              <CustomRoutes>
                <Route
                  path="/coupon-history/:promoCode"
                  element={<CouponDetails />}
                />
              </CustomRoutes>
              <CustomRoutes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/daily-report" element={<DailyReport />} />
              </CustomRoutes>
            </>
          )}
        </>
      )}
    </Admin>
  );
}

export default App;
