import React from "react";
import Big from "big.js";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const RoundCard = ({ data }: any) => {
  const { tableId, isFinalRound, scoreboard, updatedAt, startedAt } = data;

  const PlayerRow = ({ data }: any) => {
    const { playerId, username, userId, active, name, scores, totalScore } =
      data;

    const formatAmount = (amount: Big.BigSource) => {
      const formattedAmount = new Big(amount).toFixed(2);
      return formattedAmount;
    };

    return (
      <TableRow style={{ height: "150px" }}>
        <TableCell component="th" scope="row">
          <Link style={{ textDecoration: "none" }} to={`/users/${userId}/show`}>
            {username}
          </Link>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{playerId}</TableCell>
        <TableCell style={{ color: active ? "green" : "red" }}>
          {active ? "Active" : "Inactive"}
        </TableCell>
        <TableCell>
          {scores.map((score: number, index: number) => (
            <div key={index}>
              <b>Round {index + 1}:</b> {formatAmount(score)}
              {index < scores.length - 1 && (
                <div style={{ marginBottom: "8px" }} />
              )}
            </div>
          ))}
        </TableCell>
        <TableCell>{totalScore}</TableCell>
      </TableRow>
    );
  };

  const totalPlayers = scoreboard.length;
  const updatedAtDate = new Date(updatedAt);
  const roundStartedAtDate = new Date(startedAt);
  const durationInMilliseconds =
    updatedAtDate.getTime() - roundStartedAtDate.getTime();
  const hours = Math.floor(durationInMilliseconds / (60 * 60 * 1000));
  const minutes = Math.floor(
    (durationInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
  );
  const seconds = Math.floor((durationInMilliseconds % (60 * 1000)) / 1000);
  const duration = `${hours}h ${minutes}m ${seconds}s`;

  return (
    <Card>
      <CardHeader
        title={
          <React.Fragment>
            <b>Table Id:</b> {tableId} | <b>Final Round:</b>{" "}
            {isFinalRound ? "Yes" : "No"} | <b>Total Players:</b> {totalPlayers}
          </React.Fragment>
        }
        subheader={
          <React.Fragment>
            <b>Created At:</b> {new Date(startedAt).toLocaleString()} |{" "}
            <b>Updated At:</b> {new Date(updatedAt).toLocaleString()} |{" "}
            <b>Duration:</b> {duration}
          </React.Fragment>
        }
      />
      <CardContent>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>User Name</b>
                </TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Player Id</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Scores</b>
                </TableCell>
                <TableCell>
                  <b>Total Score</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreboard.map((user: any) => (
                <PlayerRow key={user.userId} data={user} />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default RoundCard;
