import { memo, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Confirm, usePermissions } from "react-admin";
import { toast } from "react-toastify";
import { get, isEmpty, toUpper } from "lodash";
import { GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  styled,
} from "@mui/material";

import UserInfoRow from "../../components/UserInfoRow";
import StatusField from "../../components/StatusField";
import SimpleTable from "../../components/SimpleTable";
import WalletTransactionModal from "../../components/WalletTransactionModal";
import ManageRoleModal from "./ManageRoleModal";
import { callRestApi } from "../../utils/callRestApi";
import config from "../../config";

const Details = () => {
  const { id } = useParams();
  const { permissions } = usePermissions();

  const navigate = useNavigate();
  const handleLinkClick = (path: any) => {
    navigate(path);
    window.location.reload();
  };

  const [userData, setUserData] = useState<{
    _id: string;
    isBlocked: boolean;
  } | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [openDeductMoney, setOpenDeductMoney] = useState(false);
  const [openManageRoles, setOpenManageRoles] = useState(false);

  useEffect(() => {
    fetchUserDetails(id);
    return () => {};
  }, []);

  const fetchUserDetails = async (id: any) => {
    setLoading(true);
    try {
      const userRes = await callRestApi(`/users/${id}`, "GET", {});
      setUserData(get(userRes, "data"));
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setLoading(false);
  };

  const gameHistoriesColumns = [
    {
      field: "tableId",
      headerName: "Table Id",
      flex: 0.3,
      renderCell: ({ row }: Partial<GridRowParams>) => {
        return (
          <Link to={`/game-histories/${row.tableId}/${row.userId}`}>
            {row.tableId}
          </Link>
        );
      },
    },
    {
      field: "joinAmount",
      headerName: "Join Amount",
      type: "number",
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => params.row.joinAmount,
    },
    {
      field: "winOrLoseAmount",
      headerName: "Win/Loss Amount",
      type: "number",
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.winOrLoseAmount,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.4,
    },
    {
      field: "outcome",
      headerName: "Outcome",
      flex: 0.4,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.6,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];

  const withdrawalsColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  const depositColumns = [
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 0.5 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
    { field: "status", headerName: "Status", flex: 0.5 },
  ];

  const referredUsersColumns = [
    {
      field: "referredUserName",
      headerName: "User Name",
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/users/${row.referredUser}/show`}
          onClick={() => handleLinkClick(`/users/${row.referredUser}/show`)}
        >
          {row.referredUserName}
        </Link>
      ),
    },
    { field: "totalMatches", headerName: "Total Matches", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];

  const adminTransactions = [
    {
      field: "transactionByUserName",
      headerName: "Transaction By",
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        const transactionByUserName = params.row.transactionByUserName;
        const transactionBy = params.row.transactionBy;
        <Link
          to={`/users/${transactionBy}/show`}
          onClick={() => handleLinkClick(`/users/${transactionBy}/show`)}
        >
          {transactionByUserName || transactionBy}
        </Link>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => {
        const type = params.row.type;
        const amount = params.row.amount;

        if (type === "deposit" || type === "bonus") {
          return <span style={{ color: "green" }}>+{amount}</span>;
        } else {
          return <span style={{ color: "red" }}>-{amount}</span>;
        }
      },
    },
    {
      field: "type",
      headerName: "Wallet",
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => {
        const wallet = params.row.wallet;
        if (wallet === "mainWallet") {
          return "Main";
        } else if (wallet === "winningWallet") {
          return "Winning";
        } else if (wallet === "bonusWallet") {
          return "Bonus";
        } else {
          return null;
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Date and Time",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${new Date(params.row.createdAt).toLocaleString()}`,
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const handleBlock = async (_id: string, isBlocked: boolean) => {
    try {
      setLoading(true);
      const endpoint = isBlocked ? "/unblock" : "/block";
      const url = `${endpoint}`;
      await callRestApi(url, "PATCH", { id: _id });

      setUserData((prevUserData) => {
        if (prevUserData) {
          return {
            ...prevUserData,
            isBlocked: !isBlocked,
          };
        }
        return prevUserData; // Return the existing state if it's null
      });

      toast(
        isBlocked
          ? "Unblocked User Successfully!"
          : "Blocked User Successfully!",
        {
          type: "success",
        }
      );
    } catch (e) {
      toast("Something Went Wrong!", {
        type: "error",
      });
    } finally {
      setLoading(false);
      setIsConfirmModalOpen(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isEmpty(userData)) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h3 style={{ marginLeft: "24px" }}>User Details</h3>
            <Item>
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Avatar
                    alt="Profile Pic"
                    src={get(
                      userData,
                      "profilePic",
                      "https://ludoempire-pub.s3.ap-south-1.amazonaws.com/default-profile-pic.png"
                    )}
                    sx={{
                      width: 100,
                      height: 100,
                      border: "3px solid #d2d6de",
                    }}
                  />
                </Box>
                <Box>
                  <UserInfoRow
                    label="User Name"
                    value={get(userData, "username", "")}
                  />
                  <UserInfoRow
                    label="Numeric Id"
                    value={get(userData, "numericId", "")}
                  />
                </Box>
                <Divider light />
                <Box marginTop={2}>
                  <UserInfoRow
                    label="Full Name"
                    value={get(userData, "name", "")}
                  />
                  <UserInfoRow
                    label="Mobile Number"
                    value={`${get(userData, "mobileNo.countryCode")}-${get(
                      userData,
                      "mobileNo.number"
                    )}`}
                  />
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Id"
                      value={`${get(userData, "email", "")}`}
                    />
                  )}
                  {get(userData, "email", "") && (
                    <UserInfoRow
                      label="E-Mail Verified"
                      value={
                        <StatusField
                          value={get(userData, "isEmailVerified", false)}
                          booleanLabel={
                            get(userData, "isEmailVerified", false)
                              ? "Verified"
                              : "Not Verified"
                          }
                          source="boolean"
                        />
                      }
                    />
                  )}
                  <UserInfoRow
                    label="Referral Code"
                    value={`${get(userData, "referrals[0].referralCode", 0)}`}
                  />
                  <UserInfoRow
                    label="Referred Users"
                    value={`${get(userData, "referrals", []).length - 1}`}
                  />
                  <UserInfoRow
                    label="Main Wallet"
                    value={`${get(userData, "wallet.mainWallet.amount", 0)}`}
                  />
                  <UserInfoRow
                    label="Win Wallet"
                    value={`${get(userData, "wallet.winningWallet.amount", 0)}`}
                  />
                  <UserInfoRow
                    label="Bonus Wallet"
                    value={`${get(userData, "wallet.bonusWallet.amount", 0)}`}
                  />

                  <UserInfoRow
                    label="KYC Status"
                    value={
                      <StatusField
                        value={toUpper(get(userData, "userKycStatus", "-"))}
                        source="kyc"
                      />
                    }
                  />
                  <UserInfoRow
                    label="Total Games Played"
                    value={`${get(userData, "statistics.totalMatches", 0)}`}
                  />
                  <UserInfoRow
                    label="Total Games Won"
                    value={`${get(userData, "statistics.winMatches", 0)}`}
                  />
                  <UserInfoRow
                    label="Total Games Lost"
                    value={`${get(userData, "statistics.lossMatches", 0)}`}
                  />
                  <UserInfoRow
                    label="Total Deposits"
                    value={`${get(
                      userData,
                      "paymentStatistics[0].totalSuccessDeposits",
                      0
                    )}`}
                  />
                  <UserInfoRow
                    label="Total Withdrawals"
                    value={`${get(
                      userData,
                      "paymentStatistics[0].totalSuccessWithdrawals",
                      0
                    )}`}
                  />
                  <UserInfoRow
                    label="Total Deposit Amount"
                    value={`₹${get(
                      userData,
                      "paymentStatistics[0].totalSuccessDepositsAmount",
                      0
                    )}`}
                  />
                  <UserInfoRow
                    label="Total Withdrawal Amount"
                    value={`₹${get(
                      userData,
                      "paymentStatistics[0].totalSuccessWithdrawalsAmount",
                      0
                    )}`}
                  />
                </Box>
                {permissions.includes("admin") && (
                  <Box marginTop={2} display="flex" flexDirection="column">
                    {get(userData, "isBlocked") ? (
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                        }}
                      >
                        UnBlock User
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                        }}
                      >
                        Block User
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="success"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenAddMoney(true)}
                    >
                      Add Money
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenDeductMoney(true)}
                    >
                      Deduct Money
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1 }}
                      onClick={() => setOpenManageRoles(true)}
                    >
                      Manage Roles
                    </Button>
                  </Box>
                )}
              </Box>
            </Item>
            <h3 style={{ marginLeft: "24px" }}>Device Information</h3>
            <Item>
              <Box>
                <UserInfoRow
                  label="Device Id"
                  value={get(userData, "devices.0.deviceId", "-")}
                />
                <UserInfoRow
                  label="Device Model"
                  value={get(userData, "devices.0.model", "-")}
                />
                <UserInfoRow
                  label="Operating System"
                  value={get(userData, "devices.0.os", "-")}
                />
                <UserInfoRow
                  label="RAM (MB)"
                  value={`${get(userData, "devices.0.ram", "-")}`}
                />
                <UserInfoRow
                  label="Processor"
                  value={get(userData, "devices.0.processor", "-")}
                />
                <UserInfoRow
                  label="Graphics Device Name"
                  value={get(userData, "devices.0.graphicsDeviceName", "-")}
                />
                <UserInfoRow
                  label="Graphics Device ID"
                  value={get(userData, "devices.0.graphicsDeviceID", "-")}
                />
                <UserInfoRow
                  label="Game Version"
                  value={get(userData, "appVersion", "-")}
                />
                <UserInfoRow
                  label="Game Code"
                  value={get(userData, "appCode", "-")}
                />
              </Box>
            </Item>
          </Grid>
          <Grid item xs={9}>
            <h3 style={{ marginLeft: "24px" }}>Games Played</h3>
            <Item>
              <SimpleTable
                columns={gameHistoriesColumns}
                data={[]}
                filter={{ userId: id }}
                endPointData={{
                  apiEndpoint: "/game-histories",
                }}
                dataMode="server"
              />
            </Item>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Withdrawals</h3>
                <Item>
                  <SimpleTable
                    columns={withdrawalsColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      txnType: "debit",
                    }}
                    endPointData={{
                      apiEndpoint: "/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "24px" }}>Deposits</h3>
                <Item>
                  <SimpleTable
                    columns={depositColumns}
                    data={[]}
                    filter={{
                      userId: id,
                      txnType: "credit",
                    }}
                    endPointData={{
                      apiEndpoint: "/transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>Referred Users</h4>
                <Item>
                  <SimpleTable
                    columns={referredUsersColumns}
                    data={[]}
                    filter={{
                      userId: id,
                    }}
                    endPointData={{
                      apiEndpoint: "/referrals",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <h4 style={{ marginLeft: "24px" }}>Admin Transactions</h4>
                <Item>
                  <SimpleTable
                    columns={adminTransactions}
                    data={[]}
                    filter={{
                      userId: id,
                    }}
                    endPointData={{
                      apiEndpoint: "/admin-wallet-transactions",
                    }}
                    dataMode="server"
                  />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isConfirmModalOpen && (
          <Confirm
            isOpen={isConfirmModalOpen}
            title={`Please Confirm the Action`}
            content="Are you sure?"
            onConfirm={() => {
              handleBlock(userData._id, userData.isBlocked);
            }}
            onClose={() => {
              setIsConfirmModalOpen(false);
            }}
          />
        )}
        {openAddMoney && (
          <WalletTransactionModal
            open={openAddMoney}
            close={() => setOpenAddMoney(false)}
            title="Add Money to Wallet"
            userId={id}
            trasnaction="credit"
          />
        )}
        {openDeductMoney && (
          <WalletTransactionModal
            open={openDeductMoney}
            close={() => setOpenDeductMoney(false)}
            title="Deduct Money from Wallet"
            userId={id}
            trasnaction="debit"
          />
        )}
        {openManageRoles && (
          <ManageRoleModal
            open={openManageRoles}
            close={() => setOpenManageRoles(false)}
            userId={id}
            userRoles={get(userData, "roles", "")}
          />
        )}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

export default memo(Details);
