import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, usePermissions } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Avatar } from "rsuite";
import { CSVLink } from "react-csv";
import { Badge, Card, CardContent, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import StatusIndicator from "../components/StatusIndicator";
import dateFormatter from "../components/DateFormatter";

interface UserProfile {
  _id: string;
  username: string;
  numericId: number;
  mobileNo: {
    countryCode: string;
    number: string;
  };
  roles: string[];
  profilePic: string;
  lastLoginAt: string;
  canBeReferred: boolean;
  profileAvatar: number;
  registrationTime: string;
  testUser: boolean;
  isAddressValid: boolean;
  userKycStatus: string;
  referral: string;
  loggedInUserRole: string;
}

const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [mobileNoFilter, setmobileNoFilter] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalAdmins, settotalAdmins] = useState(0);
  const [totalFinance, setTotalFinance] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalSupports, setTotalSupports] = useState(0);
  const [totalAdminUsersList, setTotalAdminUsersList] = useState(0);
  const [formattedData, setFormattedData] = useState([]); // Declare formattedData state
  const [lastLoginAt, setlastLoginAt] = useState("");
  const { permissions } = usePermissions();
  const hasAdmingMenuPermission = permissions.includes("admin");

  //   const [dateRange, setDateRange] = useState<[Date, Date]>([
  //     new Date(),
  //     new Date(),
  //   ]);
  const [dateRangeKey] = useState(0);
  const [tableStatus, setTableStatus] = useState("green");

  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };

  const columns = [
    {
      field: "id",
      headerName: "Username",
      flex: breakpoints.xs,
      renderCell: (params: any) => (
        <Link to={`/users/${params.row.id}/show`}>{params.row.username}</Link>
      ),
    },
    { field: "name", headerName: "Name", flex: breakpoints.xs },
    { field: "roles", headerName: "Roles", flex: breakpoints.xs },
    { field: "mobileNo", headerName: "MobileNo", flex: breakpoints.xs },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: breakpoints.xs,
      valueFormatter: (params: { value: string }) =>
        dateFormatter(params.value),
    },
    {
      field: "updatedAt",
      headerName: "UpdatedAt",
      flex: breakpoints.xs,
      valueFormatter: (params: { value: string }) =>
        dateFormatter(params.value),
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    filterGameData();
    // eslint-disable-next-line
  }, [gameData, usernameFilter, dateRangeKey]);

  useEffect(() => {
    fetchGameData();
    fetchUserProfile();
    // eslint-disable-next-line
  }, [paginationModel, dateRangeKey]);

  useEffect(
    () => {
      if (formattedData.length > 0) {
        fetchUserProfile();
        // eslint-disable-next-line
      }
    }, // eslint-disable-next-line
    [formattedData]
  );

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      let apiUrl = `${config.REST_ENDPOINT}/admin-users-profile?key=${config.MAINTENANCE_BYPASS_KEY}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = response.data;

      const formattedData = data.adminUsersList.map(
        (adminUser: {
          name: any;
          _id: any;
          username: any;
          mobileNo: { number: any };
          roles: any;
          createdAt: any;
          updatedAt: any;
        }) => ({
          id: adminUser._id,
          name: adminUser.name,
          username: adminUser.username,
          mobileNo: adminUser.mobileNo.number,
          roles: adminUser.roles,
          createdAt: adminUser.createdAt,
          updatedAt: adminUser.updatedAt,
        })
      );
      setFormattedData(formattedData);
      setGameData(formattedData);
      if (response.data.totalPlayers > 0) {
        setTableStatus("green");
      } else {
        setTableStatus("red");
      }
      setTotalItemsCount(data.totalAdminUsersList);
      setTotalSupports(data.totalSupports);
      setTotalFinance(data.totalFinances);
      settotalAdmins(data.totalAdmins);
      setTotalPlayers(data.totalPlayers);
      setTotalAdminUsersList(data.totalAdminUsersList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const fetchUserProfile = async () => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        throw new Error("You have no access");
      }

      let apiUrl = `${config.REST_ENDPOINT}/users/me`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const userProfileData = response.data;

      const lastLoginAt = userProfileData.lastLoginAt;

      const formattedLastLoginAt = new Date(lastLoginAt).toLocaleString(
        "en-IN"
      );

      setlastLoginAt(formattedLastLoginAt);

      const loggedInUserId = userProfileData._id;

      const matchingUser = formattedData.find(
        (user: any) => user.id === loggedInUserId
      );

      if (matchingUser) {
        if ((matchingUser as any).roles.includes("admin")) {
          userProfileData.loggedInUserRole = "Admin";
        } else if ((matchingUser as any).roles.includes("support")) {
          userProfileData.loggedInUserRole = "Support";
        } else if ((matchingUser as any).roles.includes("finance")) {
          userProfileData.loggedInUserRole = "Finance";
        } else {
          userProfileData.loggedInUserRole = "Unknown";
        }
      } else {
        userProfileData.loggedInUserRole = "Unknown";
      }

      setUserProfile(userProfileData);
    } catch (error: any) {
      console.error("Error fetching user profile:", error);
      toast.error(error.message || "Error fetching user profile");
    }
  };

  const filterGameData = () => {
    let filteredData = gameData;

    if (usernameFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    if (mobileNoFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.mobileNo.toLowerCase().includes(mobileNoFilter.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  };

  const csvData = React.useMemo(() => {
    return filteredData.map((item: any) => ({
      Username: item.username,
      Roles: item.roles,
      "Mobile No": item.mobileNo,
      CreatedAt: item.createdAt,
      UpdatedAt: item.updatedAt,
    }));
  }, [filteredData]);

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>Admin Profile</h2>

      {userProfile && (
        <Card
          elevation={3}
          style={{
            marginBottom: "20px",
            textAlign: "left",
            width: "300px",
            height: "280px",
            margin: "auto",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                fontFamily: "Playfair Display",
                marginBottom: "20px",
                color: "#007bff",
              }}
            >
              Profile Information
            </Typography>
            <Box
              style={{
                marginBottom: "20px",
              }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                style={{ marginLeft: "85px" }}
              >
                <Avatar
                  alt="Skill Patti"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                />
              </StyledBadge>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>User Name:</strong> {userProfile.username}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>Mobile:</strong>{" "}
                {`(+${userProfile.mobileNo.countryCode}) ${userProfile.mobileNo.number}`}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong>Last Login At:</strong> {lastLoginAt}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: "8px", color: "#555" }}
              >
                <strong> My role is:</strong> {userProfile.loggedInUserRole}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}

      <div>
        <div
          style={{
            display: "inline-block",
            marginRight: "10px",
            marginTop: "5px",
          }}
        >
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalAdminUsersList}
            label="Total Admins"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalAdmins}
            label="Total Super Admins"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalSupports}
            label="Total Support Admins"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalFinance}
            label="Total Finance Admins"
          />
          <StatusIndicator
            backgroundColor={tableStatus}
            total={totalPlayers}
            label="Total Players"
          />
        </div>
      </div>

      <Grid container spacing={2}>
        {hasAdmingMenuPermission && (
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Search by Username"
                variant="outlined"
                fullWidth
                value={usernameFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setUsernameFilter(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Search by Mobile No"
                variant="outlined"
                fullWidth
                value={mobileNoFilter}
                onChange={(e) => {
                  setPaginationModel({ page: 0, pageSize: 10 });
                  setmobileNoFilter(e.target.value);
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={3}>
          {hasAdmingMenuPermission && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginRight: "20px",
                marginTop: "10px",
              }}
            >
              <CSVLink
                data={csvData}
                filename={`Admin_users_list_data_${new Date().toLocaleDateString()}.csv`}
                className="btn btn-primary"
                style={{ position: "absolute", left: "94%" }}
              >
                <span>Download CSV</span>
              </CSVLink>
            </div>
          )}
        </Grid>
      </Grid>

      {hasAdmingMenuPermission && (
        <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
          <DataGrid
            columns={columns}
            rows={filteredData}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
            paginationMode="server"
            autoHeight
            rowCount={totalItemsCount}
          />
        </Paper>
      )}
      <ReactQueryDevtools />
    </div>
  );
};

export default AdminPage;
