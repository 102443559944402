import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../../utils/sessionData";
import config from "../../config";

export default function BlockedDevices() {
  const [configState, setConfigState] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [setDataState] = useState<any>({});

  useEffect(() => {
    (async () => {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const { data } = await axios.get(
        config.REST_ENDPOINT +
          `/blocked-device?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setConfigState(convertConfigToState(data));
      setDataState(data);
    })();
  }, []);

  const convertConfigToState = (data: any) => {
    const { items } = data;
    return {
      items,
    };
  };

  const onSave = async () => {
    const { registrationTime } = configState;
    if (!dayjs(registrationTime).year()) {
      toast.error("Please input a valid date in the registration time!");
      return;
    }

    const method = "PUT";
    const blockedDeviceList = configState.items;
    const updateData = {
      blockedDeviceList,
    };

    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(
        config.REST_ENDPOINT +
          `/blocked-device?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        toast.success("Blocked Devices Data updated successfully!");
      } else {
        toast.error(
          "Failed to update Blocked Devices. Please try again later."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const updateBlockedDevice = (
    index: number,
    value: string,
    fieldName: string
  ) => {
    setDisabled(false);
    let temp: any = { ...configState };
    let tempPayoutMethods = [...temp.items];

    const parsedValue =
      fieldName === "graphicsDeviceID" ? parseInt(value, 10) : value;

    tempPayoutMethods[index] = {
      ...tempPayoutMethods[index],
      [fieldName]: parsedValue,
    };
    temp.items = tempPayoutMethods;
    setConfigState(temp);
  };

  const { items } = configState;

  const handleDeleteBlockedDevice = (index: number) => {
    const updatedPayoutMethods = [...configState.items];
    updatedPayoutMethods.splice(index, 1);

    const updatedConfigState = { ...configState, items: updatedPayoutMethods };
    setConfigState(updatedConfigState);
    setDisabled(false);
  };

  const handleAddBlockedDevice = () => {
    const newBlockedDevice = "";
    const updatedPayoutMethods = [...items, newBlockedDevice];
    let temp: any = { ...configState };
    temp.items = updatedPayoutMethods;
    setConfigState(temp);
  };

  return (
    <Paper
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 0.1, width: "25ch" },
        padding: "35px",
      }}
      noValidate
      autoComplete="off"
    >
      <Grid item sm={2} style={{ textAlign: "center" }}>
        <Typography
          variant="h3"
          style={{ margin: "10px", fontFamily: "Playfair Display" }}
        >
          Blocked Devices
        </Typography>
      </Grid>

      <Grid item sm={10}>
        <Table
          sx={{
            border: "1px solid black",
            maxHeight: "400px",
            borderBottom: "1px solid black",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Device ID</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>Processor</TableCell>
              <TableCell>Graphics Device Name</TableCell>
              <TableCell>Graphics Device ID</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((method: any, index: any) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    name={`items[${index}].deviceId`}
                    multiline
                    value={method.deviceId}
                    onChange={(e) =>
                      updateBlockedDevice(index, e.target.value, "deviceId")
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`items[${index}].model`}
                    multiline
                    value={method.model}
                    onChange={(e) =>
                      updateBlockedDevice(index, e.target.value, "model")
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`items[${index}].os`}
                    multiline
                    value={method.os}
                    onChange={(e) =>
                      updateBlockedDevice(index, e.target.value, "os")
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`items[${index}].processor`}
                    multiline
                    value={method.processor}
                    onChange={(e) =>
                      updateBlockedDevice(index, e.target.value, "processor")
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`items[${index}].graphicsDeviceName`}
                    multiline
                    value={method.graphicsDeviceName}
                    onChange={(e) =>
                      updateBlockedDevice(
                        index,
                        e.target.value,
                        "graphicsDeviceName"
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`items[${index}].graphicsDeviceID`}
                    type="number"
                    value={method.graphicsDeviceID}
                    onChange={(e) =>
                      updateBlockedDevice(
                        index,
                        e.target.value,
                        "graphicsDeviceID"
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteBlockedDevice(index)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box mt={4} mb={2} display="flex" justifyContent="center">
          <Button
            onClick={handleAddBlockedDevice}
            variant="contained"
            color="success"
          >
            Add a Device
          </Button>
        </Box>
        <Box mt={4} mb={2} display="flex" justifyContent="center">
          <Button disabled={disabled} onClick={onSave} variant="contained">
            Save
          </Button>
        </Box>
      </Grid>
    </Paper>
  );
}
