import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography, Box } from "@mui/material";
import { DateRangePicker } from "rsuite";
import {
  AccountBalanceWallet,
  MonetizationOn,
  SportsEsports,
  People,
  CheckCircle,
} from "@mui/icons-material";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import StatsCard from "../components/StatsCard";
import NearMeIcon from "@mui/icons-material/NearMe";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import SendIcon from "@mui/icons-material/Send";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    totalSuccessDepositAmount: 0,
    totalSuccessWithdrawalAmount: 0,
    totalGames: 0,
    totalCompletedKyc: 0,
    totalSuccessfulReferredUsers: 0,
    MostUsedreferralCode: 0,
    MostUsedReferralCodeCount: 0,
  });
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [userTotalCount, setUserTotalCount] = useState(0);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const filter = {
        startDate,
        endDate,
      };

      const apiEndpoints = [
        `${config.REST_ENDPOINT}/payment-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/gameplay-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/users-graph?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/kyc-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        `${config.REST_ENDPOINT}/referrals/stats?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
      ];

      const apiRequests = apiEndpoints.map((endpoint) =>
        axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
      );

      const responses = await Promise.all(apiRequests);
      const paymentDashboardData = responses[0].data;
      const gameplayDashboardData = responses[1].data;
      const usersResponse = responses[2].data;
      const userTotalCount = usersResponse.Totalcount.totalCount || 0;
      const totalCompletedKyc = responses[3].data;
      const totalSuccessfulReferredUsers = responses[4].data;
      const MostUsedreferralCode = responses[4].data.maximum.referralCode;
      const MostUsedReferralCodeCount = responses[4].data.maximum.totalNum;

      setStats({
        totalSuccessDepositAmount:
          paymentDashboardData.totalSuccessDepositAmount,
        totalSuccessWithdrawalAmount:
          paymentDashboardData.totalSuccessWithdrawalAmount,
        totalGames: gameplayDashboardData.totalgames,
        totalCompletedKyc: totalCompletedKyc.totalCompletedKyc,
        totalSuccessfulReferredUsers:
          totalSuccessfulReferredUsers.totalSuccessfulReferredUsers,
        MostUsedreferralCode: MostUsedreferralCode,
        MostUsedReferralCodeCount: MostUsedReferralCodeCount,
      });

      setUserTotalCount(userTotalCount);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <Box>
      <h2 style={{ fontFamily: "Playfair Display" }}>Main Dashboard</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <Grid container spacing={3}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Success Deposit Amount
                  </Typography>
                }
                value={stats.totalSuccessDepositAmount || 0}
                icon={
                  <MonetizationOn
                    style={{ fontSize: "20px", color: "#9C27B0" }}
                  />
                }
                isAmount
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Success Withdrawal Amount
                  </Typography>
                }
                value={stats.totalSuccessWithdrawalAmount || 0}
                icon={
                  <AccountBalanceWallet
                    style={{ fontSize: "20px", color: "#FF9800" }}
                  />
                }
                isAmount
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Savings
                  </Typography>
                }
                value={
                  stats.totalSuccessDepositAmount -
                    stats.totalSuccessWithdrawalAmount || 0
                }
                icon={
                  <DataSaverOnIcon
                    style={{ fontSize: "20px", color: "#33cc33" }}
                  />
                }
                isAmount
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography fontWeight="bold">Total Played Games</Typography>
                }
                value={stats.totalGames}
                icon={
                  <SportsEsports
                    style={{ fontSize: "20px", color: "#009933" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Registered User Count
                  </Typography>
                }
                value={userTotalCount}
                icon={<People style={{ fontSize: "20px", color: "#6699CC" }} />}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Completed KYC
                  </Typography>
                }
                value={stats.totalCompletedKyc}
                icon={
                  <CheckCircle style={{ fontSize: "20px", color: "#6699CC" }} />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Most Used Referral Code
                  </Typography>
                }
                value={stats.MostUsedreferralCode}
                value2={stats.MostUsedReferralCodeCount}
                icon={
                  <SendIcon style={{ fontSize: "20px", color: "#4169E1" }} />
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StatsCard
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Successful Referred Users
                  </Typography>
                }
                value={stats.totalSuccessfulReferredUsers}
                icon={
                  <NearMeIcon style={{ fontSize: "20px", color: "#FF33BB" }} />
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;
