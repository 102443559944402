import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../../config";
import { getItemWithExpiry } from "../../utils";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const CouponDetails = () => {
  const { promoCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [couponData, setCouponData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all"); // Status filter

  const navigate = useNavigate();
  const handleLinkClick = (path: any) => {
    navigate(path);
    window.location.reload();
  };

  const couponColumns = [
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/users/${row.userId}/show`}
          onClick={() => handleLinkClick(`/users/${row.userId}/show`)}
        >
          {row.username}
        </Link>
      ),
    },
    {
      field: "appliedAt",
      headerName: "Applied At",
      flex: 1,
    },
    {
      field: "orderId",
      headerName: "Order Id",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params: any) => {
        const status = params.value;
        if (status === "success") {
          return { value: status, color: "green" };
        } else if (status === "pending") {
          return { value: status, color: "orange" };
        } else {
          return { value: status, color: "red" };
        }
      },
      renderCell: (params: any) => (
        <div style={{ color: params.value.color }}>{params.value.value}</div>
      ),
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const [usernameFilter, setUsernameFilter] = useState("");
  const [orderIdFilter, setOrderIdFilter] = useState("");
  useEffect(() => {
    filterCouponData();
  }, [couponData, usernameFilter, orderIdFilter, filterStatus]);

  useEffect(() => {
    fetchCouponDetails();
  }, [paginationModel, filterStatus]);

  const fetchCouponDetails = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }
      const { page, pageSize } = paginationModel;
      let query = `&skip=${pageSize * page}&count=${pageSize}`;

      const response = await axios.get(
        `${config.REST_ENDPOINT}/coupon-history/${promoCode}?key=${config.MAINTENANCE_BYPASS_KEY}${query}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const responseData = response?.data || [];
      setCouponData(responseData);
      setLoading(false);
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
  };

  const filterCouponData = () => {
    let filteredData = couponData;

    if (filterStatus !== "all") {
      filteredData = filteredData.filter(
        (item: any) => item.status === filterStatus
      );
    }

    if (usernameFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    if (orderIdFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.orderId.toLowerCase().includes(orderIdFilter.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  };

  const getRowId = (row: any) => row.userId;

  return (
    <div>
      <Typography
        variant="h4"
        style={{ marginLeft: "5px", marginTop: "10px", fontWeight: "bold" }}
      >
        Coupon Users
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search by User Name"
            variant="outlined"
            fullWidth
            value={usernameFilter}
            onChange={(e) => setUsernameFilter(e.target.value)}
            style={{ height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search by Order Id"
            variant="outlined"
            fullWidth
            value={orderIdFilter}
            onChange={(e) => setOrderIdFilter(e.target.value)}
            style={{ height: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{ marginBottom: "10px", marginTop: "-15px" }}
        >
          <InputLabel id="status-label">Filter by Status</InputLabel>
          <Select
            labelId="status-label"
            variant="outlined"
            fullWidth
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            style={{ height: "50%" }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Paper elevation={3} style={{ marginTop: "-2px", padding: "20px" }}>
        <DataGrid
          columns={couponColumns}
          rows={filteredData}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
          getRowId={getRowId}
        />
      </Paper>
      <ReactQueryDevtools />
    </div>
  );
};

export default CouponDetails;
