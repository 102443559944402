import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { every, identity, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useState } from "react";

import { callRestApi } from "../utils/callRestApi";
import config from "../config";

const WalletTransactionModal = (props: any) => {
  const { open, close, userId, title, trasnaction, notify } = props;

  const [formData, setFormData] = useState({
    amount: "",
    wallet: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setMessage("");
    if (!every(formData, identity)) {
      setMessage("All fields are mandatory.");
      return;
    }
    if (trasnaction === "credit") {
      try {
        setLoading(true);
        const response = await callRestApi(`/add-money-to-wallet`, "POST", {
          id: userId,
          ...formData,
        });
        response?.data?.statusCode === 201
          ? toast(response?.data?.message || "Wallet Updated. Please Check", {
              type: "success",
            })
          : toast(response?.data?.message || "Something Wrong", {
              type: "error",
            });
      } catch (error: any) {
        toast(
          error?.response?.data?.message || error?.message || "Something Wrong",
          {
            type: "error",
          }
        );
      }
    } else if (trasnaction === "debit") {
      try {
        setLoading(true);
        const response = await callRestApi(
          `/deduct-money-from-wallet`,
          "POST",
          { id: userId, ...formData }
        );
        response?.data?.statusCode === 201
          ? toast(response?.data?.message || "Wallet Updated. Please Check", {
              type: "success",
            })
          : toast(response?.data?.message || "Something Wrong", {
              type: "error",
            });
      } catch (error: any) {
        toast(
          error?.response?.data?.message || error?.message || "Something Wrong",
          {
            type: "error",
          }
        );
      }
    }
    setLoading(false);
    close();
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isEmpty(message) && (
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          <FormControl fullWidth>
            <TextField
              id="amount"
              type="text"
              label="Amount"
              variant="outlined"
              onChange={(event) => {
                setFormData((prev: any) => ({
                  ...prev,
                  amount: event.target.value,
                }));
              }}
            />
          </FormControl>
          <Box>
            <FormControl>
              <FormLabel id="walletLabel">Wallet</FormLabel>
              <RadioGroup
                row
                aria-labelledby="walletLabel"
                name="wallet"
                onChange={(event: any) => {
                  setFormData((prev) => ({
                    ...prev,
                    wallet: event.target.value,
                  }));
                }}
              >
                <FormControlLabel
                  value="mainWallet"
                  control={<Radio />}
                  label="Main Wallet"
                />
                <FormControlLabel
                  value="winningWallet"
                  control={<Radio />}
                  label="Winning Wallet"
                />
                <FormControlLabel
                  value="bonusWallet"
                  control={<Radio />}
                  label="Bonus Wallet"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="info" variant="contained">
          Close
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={onSave}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletTransactionModal;
