import { useParams } from "react-router";
import querystring from "query-string";
import { memo, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";

import RoundCard from "./RoundCard";
import { callRestApi } from "../../utils/callRestApi";
import Item from "../../components/GridItem";

const ScoreBoard = () => {
  const { tableId } = useParams();
  const [apiData, setApiData] = useState([]);
  useEffect(() => {
    fetchData();
  });

  const fetchData = async () => {
    const query = {
      tableId: tableId,
    };
    try {
      const apiResponse = await callRestApi(
        `/records/scoreboard`,
        "GET",
        {},
        querystring.stringify(query)
      );
      setApiData(() => apiResponse?.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          {!isEmpty(apiData) ? (
            <>
              <h2
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                Scoreboard
              </h2>
              <RoundCard data={apiData} />
            </>
          ) : (
            <Item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <h4>No Scoreboard found</h4>
              </Box>
            </Item>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ScoreBoard);
