import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  usePermissions,
  FunctionField,
  DateTimeInput,
} from "react-admin";
import { Stack, Typography } from "@mui/material";
import UserNameHyperlink from "../components/UserNameHyperlink";

const Withdrawals = (props: object) => {
  const { permissions } = usePermissions();

  const payoutStatusChoices = [
    { id: "SUCCESS", name: "Success" },
    { id: "FAILED", name: "Failed" },
    { id: "PENDING", name: "Pending" },
  ];
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Payout Status (Records from Sept 04 2023)"
      source="payoutStatus"
      choices={payoutStatusChoices}
    />,
    <SelectArrayInput
      label="Filter by CashFree Payout Status (Records before Sept 04 2023)"
      source="cashfreePayoutStatus"
      choices={payoutStatusChoices}
    />,

    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Withdrawals</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="currency" />
          <TextField source="amount" />
          <TextField source="paymentMode" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <TextField source="txnMode" />
          <TextField source="txnType" />
          <FunctionField
            label="Status"
            render={(record: any) => {
              if (record.payoutStatus) {
                if (record.payoutStatus === "PENDING") {
                  return <ChipField source="payoutStatus" color="warning" />;
                } else if (record.payoutStatus === "SUCCESS") {
                  return <ChipField source="payoutStatus" color="success" />;
                } else {
                  return <ChipField source="payoutStatus" color="error" />;
                }
              } else {
                if (record.cashfreePayoutStatus === "PENDING") {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="warning" />
                  );
                } else if (record.cashfreePayoutStatus === "SUCCESS") {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="success" />
                  );
                } else {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="error" />
                  );
                }
              }
            }}
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Withdrawals;
