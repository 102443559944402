import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useRefresh } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { DateRangePicker } from "rsuite";
import { CSVLink } from "react-csv";

const TopDeposits = () => {
  // eslint-disable-next-line
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [dateRangeKey, setDateRangeKey] = useState(0);

  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };

  const columns = [
    {
      field: "id",
      headerName: "Username",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <Link to={`/users/${params.row.id}/show`}>{params.row.username}</Link>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Successful Deposit  Amount",
      flex: breakpoints.md,
    },
    {
      field: "totalCount",
      headerName: "Total Successful Deposit Count",
      flex: breakpoints.md,
    },
    {
      field: "gameCount",
      headerName: "Total Game Count",
      flex: breakpoints.md,
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    filterGameData();
    // eslint-disable-next-line
  }, [gameData, usernameFilter, dateRangeKey]);

  useEffect(() => {
    fetchGameData();
    // eslint-disable-next-line
  }, [paginationModel, dateRangeKey]);

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");

      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      let apiUrl = `${config.REST_ENDPOINT}/top-deposits?key=${config.MAINTENANCE_BYPASS_KEY}`;

      if (dateRange[0] !== null && dateRange[1] !== null) {
        const startDateFetch = dateRange[0] as Date;
        startDateFetch.setHours(0, 0, 0, 0);
        const startDate = new Date(
          startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const endDateFetch = dateRange[1] as Date;
        endDateFetch.setHours(23, 59, 59, 999);
        const endDate = new Date(
          endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
        ).toJSON();
        const filter = {
          startDate,
          endDate,
        };
        apiUrl += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
      }

      const { page, pageSize } = paginationModel;
      const skip = pageSize * page;
      const count = pageSize;
      apiUrl += `&skip=${skip}&count=${count}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const data = response.data;
      const formattedData = data.items.map((item: any) => ({
        id: item._id,
        username: item.username,
        totalAmount: item.totalAmount,
        totalCount: item.totalCount,
        gameCount: item.gameCount,
      }));

      setGameData(formattedData);
      setTotalItemsCount(data.meta.totalCount);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  const filterGameData = () => {
    let filteredData = gameData;

    if (usernameFilter) {
      filteredData = filteredData.filter((item: any) =>
        item.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    setFilteredData(filteredData);
  };

  const handleDateSelect = (value: any) => {
    setDateRange(value);
    setDateRangeKey((prevKey) => prevKey + 1);
  };

  const csvData = React.useMemo(() => {
    return filteredData.map((item: any) => ({
      Username: item.username,
      "Total Successful Deposits Amount": item.totalAmount,
      "Total Successful Deposits Count": item.totalCount,
      "Total Game Count": item.gameCount,
    }));
  }, [filteredData]);

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>Top Deposits</h2>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Search by Username"
            variant="outlined"
            fullWidth
            value={usernameFilter}
            onChange={(e) => {
              setPaginationModel({ page: 0, pageSize: 10 });
              setUsernameFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <p style={{ marginRight: "10px" }}>
              <strong>Select Date Range:</strong>
            </p>
            <DateRangePicker value={dateRange} onChange={handleDateSelect} />
            <CSVLink
              data={csvData}
              filename={`top_withdrawals_data_${new Date().toLocaleDateString()}.csv`}
              className="btn btn-primary"
              style={{ position: "absolute", left: "94%" }}
            >
              <span>Download CSV</span>
            </CSVLink>
          </div>
        </Grid>
      </Grid>

      <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
        <DataGrid
          columns={columns}
          rows={filteredData}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50]}
          paginationMode="server"
          autoHeight
          rowCount={totalItemsCount}
        />
      </Paper>
      <ReactQueryDevtools />
    </div>
  );
};

export default TopDeposits;
