import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { startCase } from "lodash";
import { SupervisedUserCircle } from "@mui/icons-material";

import { Roles, roleValidationSchema } from "../../schema/roleValidation";
import { callRestApi } from "../../utils/callRestApi";
import config from "../../config";

type FormData = {
  roles: string[];
};

const ManageRoleModal = (props: any) => {
  const { open, close, userId, userRoles = [] } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: joiResolver(roleValidationSchema),
    defaultValues: {
      roles: userRoles,
    },
  });

  const onSubmit = async (payload: FormData) => {
    setIsLoading(true);
    try {
      const endPoint = `/update-roles/${userId}`;
      const response = await callRestApi(endPoint, "PATCH", payload);
      if (response?.data?.status) {
        toast(
          response?.data?.message
            ? response?.data?.message
            : "Roles Updated. Please Refresh page to verify",
          {
            type: "success",
          }
        );
      } else {
        toast("Something Wrong. Please Try Again", {
          type: "success",
        });
      }
      setIsLoading(false);
      close()
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        {
          type: "error",
        }
      );
    }
    setIsLoading(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <SupervisedUserCircle />
          Manage User Roles
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>Update User Roles</Typography>

            <Autocomplete
              multiple
              id="roles"
              options={Object.values(Roles)}
              renderTags={(value: string[], getTagProps: any) =>
                value.map((option: string, index: number) => (
                  <Chip
                    key={index}
                    label={startCase(option)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Roles"
                  error={Boolean(errors.roles)}
                  helperText={
                    errors.roles?.message || errors.roles?.[0]?.message
                  }
                />
              )}
              onChange={(event: React.ChangeEvent<{}>, value: string[]) =>
                setValue("roles", value)
              }
              defaultValue={userRoles}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="info" variant="contained">
            Close
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ManageRoleModal;
