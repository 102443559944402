import React, { useMemo, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { Slide } from "@mui/material";
import config from "../config";

export default function LoginPage() {
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [inputOtp, setInputOtp] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputOtp) {
      axios
        .post(`/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`, {
          mobileNo,
        })
        .then((result) => {
          if (result.status === 201) {
            if (new Date(result.data.expiresAt) < new Date())
              notify(`OTP is expired`);
            else {
              notify(`OTP is sent to (${mobileNo})`);
              setInputOtp(true);
            }
          }
        })
        .catch(() =>
          notify("Error Sending OTP! Please try again after some time.")
        );
    } else {
      login({ mobileNo, otp }).catch((err) => {
        if (err.response?.status === 401) notify("OTP is invalid");
        else notify(err.response?.data?.message || err.message);
      });
    }
  };

  const mobileNumComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Input Phone Number
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              value={mobileNo}
              onChange={(e) => setMobileNo(e.target.value)}
              fullWidth
              name="otp"
              label="Mobile Number"
              id="otp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );

  const otpComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify OTP
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              name="otp"
              label="OTP Code"
              id="otp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Button
                  variant="text"
                  onClick={() => {
                    setOtp("");
                    notify("Sent OTP again.");
                  }}
                >
                  Send OTP again
                </Button>
              </Grid>
              <Grid item>
                <Button variant="text" onClick={() => setInputOtp(false)}>
                  Use another phone
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Slide>
  );

  const signInComp = useMemo(
    () => (inputOtp ? otpComp : mobileNumComp),
    [inputOtp, otp, mobileNo]
  );

  return signInComp;
}
