import {
  TopToolbar,
  ExportButton,
  List,
  SelectColumnsButton,
  TextField,
  Filter,
  SearchInput,
  usePermissions,
  DatagridConfigurable,
  BooleanField,
  FilterForm,
  FilterButton,
  useTranslate,
} from "react-admin";

import UserNameHyperlink from "../../components/UserNameHyperlink";
import { Chip, Stack } from "@mui/material";

const BlockedUsers = (props: object) => {
  const { permissions } = usePermissions();

  const QuickFilter = ({ label }: any) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
  };

  const transactionFilters = [
    <QuickFilter
      source="isAdminBlocked"
      label="Admin Blocked"
      defaultValue={true}
    />,
    <QuickFilter
      source="isDeviceBlocked"
      label="Device Blocked"
      defaultValue={true}
    />,
  ];

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Name"
        source="name"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Blocked Users</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="name" label="Name" />
          <BooleanField source="isAdminBlocked" label="Blocked By Admin" />
          <BooleanField source="isDeviceBlocked" label="Device Blocked" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default BlockedUsers;
